#login, #login .btn, .login-page {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

#email-message, #password-message {
  position: absolute;
  left: 300px;
  white-space: nowrap; }

.login-page {
  background: #f8f8f8; }

#login {
  margin: auto;
  padding: 80px 0 0;
  width: 320px; }

#login img {
  margin: 0 12px 30px; }

#login form {
  border-radius: 6px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  background: #f1840a;
  margin-bottom: 15px; }

#login .form-control {
  box-shadow: none !important;
  border-color: #fff !important; }

#login .btn-orange {
  background-color: #e67e22;
  color: #fff;
  border: 2px solid #fff; }

#login .btn-orange:hover {
  background-color: #f38b2f;
  color: #fff; }

#login form p {
  margin: 0 0 20px;
  font-size: 13px; }

#login a {
  border-bottom: 1px dotted #ccc; }

#email-message:empty, #password-message:empty {
  opacity: 0;
  transition: all 0s ease 0s; }

#email-message {
  top: 30px; }

#password-message {
  top: 6px; }

.warning {
  background-color: #e74c3c;
  border-radius: 4px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
  opacity: 1;
  padding: 4px 8px;
  transition-duration: .5s; }

#login .btn {
  text-transform: none;
  letter-spacing: 0; }

html {
  font-size: 62.5%;
  height: 100%; }

body {
  font-size: 1.5em;
  /* currently ems cause chrome bug misinterpreting rems on body element */
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  height: 100%; }
  body.pushy-open-right {
    overflow-y: hidden; }

* {
  outline: none !important; }

/*========== TYPOGRAPHY ==========*/
h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif !important;
  color: #000;
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 500;
  color: #C92838;
  position: relative;
  text-rendering: optimizeLegibility; }

h1 {
  font-size: 3.4rem;
  line-height: 1.5; }

h2 {
  font-size: 3.2rem;
  line-height: 1.4; }

h3 {
  font-size: 2.6rem;
  line-height: 1.3; }

h4 {
  font-size: 2.2rem;
  line-height: 1.2; }

h5 {
  font-size: 2rem;
  line-height: 1.2; }

h6 {
  font-size: 1.6rem;
  line-height: 1.6; }

/* Larger than phablet */
@media (min-width: 550px) {
  h1 {
    font-size: 3.8rem; }
  h2 {
    font-size: 3.4rem; }
  h3 {
    font-size: 3rem; }
  h4 {
    font-size: 2.6rem; }
  h5 {
    font-size: 2rem; }
  h6 {
    font-size: 1.5rem; } }

/*========== BTNS ==========*/
.btn {
  border-radius: 0;
  padding: 10px 15px; }

/*========== FORMS ==========*/
.form-control {
  box-shadow: none;
  border-radius: 0; }

/*========== HELPERS ==========*/
.ctn-padding-top {
  padding-top: 180px !important; }

.nav-up {
  top: -160px; }

.header-fixed {
  position: fixed; }

.kambor {
  border: solid 2px red; }

.h1-404 {
  margin-top: 100px;
  margin-bottom: 100px; }

.logo-z-index {
  z-index: 10; }

.logo-holder-z-index {
  z-index: 9; }

.body__header-margin {
  margin-top: -80px; }

.body__header-margin2 {
  margin-top: -80px; }

.abcabc {
  height: 0px; }

.headv1__fixed {
  position: fixed; }

.headv1__solid {
  background-color: #C92838; }

.headv1__solid2 {
  background-color: #C92838; }

.headv1__active {
  background-color: #C92838; }

nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.t {
  display: table;
  table-layout: fixed;
  width: 100%; }
  .t .td {
    display: table-cell;
    vertical-align: middle; }

.row.hg {
  margin-left: -8px;
  margin-right: -8px; }
  .row.hg [class*="col-"] {
    padding-left: 8px;
    padding-right: 8px; }

.p-t-0 {
  padding-top: 0 !important; }

.p-b-0 {
  padding-bottom: 0 !important; }

/*========== GLOBAL CONTAINERS ==========*/
.container {
  position: relative; }

.ctn {
  padding: 30px 0; }
  .ctn.bg--grey {
    background-color: #eee; }

.fl__row {
  display: -moz-flexbox;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.fl__item {
  display: -moz-flexbox;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex; }

.socialIcons {
  padding: 40px 0px;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  display: -ms-flexbox;
  display: flex;
  flexwrap: nowrap;
  -ms-flex-pack: left;
  justify-content: left;
  border: solid 2px rgba(255, 255, 255, 0.2);
  border-left: 0;
  border-right: 0; }
  .socialIcons .social-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 20px;
    padding: 5px;
    font-size: 1.8rem;
    margin: 0 10px;
    color: #C92838;
    transition-duration: 300ms; }
    .socialIcons .social-link.facebook {
      background-color: #fff; }
      .socialIcons .social-link.facebook:hover {
        background-color: #4162a7; }
    .socialIcons .social-link.twitter {
      background-color: #fff; }
      .socialIcons .social-link.twitter:hover {
        background-color: #6cb7f0; }
    .socialIcons .social-link.linkedin {
      background-color: #fff; }
      .socialIcons .social-link.linkedin:hover {
        background-color: #0088cf; }
    .socialIcons .social-link.youtube {
      background-color: #fff; }
      .socialIcons .social-link.youtube:hover {
        background-color: #d42a2a; }

/*========== HEADER ==========*/
.headv1__dropdown--search {
  background-color: #E1495E;
  max-width: 200px;
  border: none;
  right: 0;
  left: auto; }
  .headv1__dropdown--search .form-control {
    box-shadow: none !important;
    border: 1px solid #fff; }
  .headv1__dropdown--search .btn {
    height: 34px;
    width: 34px;
    padding: 0;
    background-color: #C92838; }
    .headv1__dropdown--search .btn i {
      padding: 0;
      color: #fff; }

header {
  background-color: transparent;
  z-index: 10; }

.headv1 {
  width: 100%; }

.headv1__top {
  position: relative; }
  .headv1__top .container {
    padding: 0; }

.headv1__logo {
  display: inline-block;
  width: 80px;
  position: absolute;
  left: 50px;
  top: 8px;
  transition-duration: 0.3s; }

.headv1__logo-container {
  display: -ms-flexbox;
  display: flex;
  flexwrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  background-image: url(/img/shield.png);
  background-repeat: no-repeat;
  height: 156px;
  width: 263px; }

.headv1__topmenu {
  display: inline-block;
  width: 280px;
  margin-left: 115px;
  float: right;
  height: 60px; }
  .headv1__topmenu ul {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 120px; }
    .headv1__topmenu ul li {
      display: table-cell;
      vertical-align: middle;
      height: 100%; }
      .headv1__topmenu ul li > a {
        height: 100%;
        text-align: center;
        font-size: 1.8rem; }
      .headv1__topmenu ul li .dropdown-menu {
        border: medium none;
        border-radius: 0;
        left: auto;
        margin: 0;
        right: 0;
        width: 240px; }

.headv1__dropdown {
  padding: 15px; }

.product-table__item {
  table-layout: auto; }
  .product-table__item .img {
    width: 40px; }
  .product-table__item .details {
    padding: 0 10px; }
    .product-table__item .details .details__item {
      display: block;
      font-size: 1.2rem; }
      .product-table__item .details .details__item.details__item--title {
        font-size: 1.3rem;
        font-weight: 600; }
  .product-table__item + .product-table__item {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #eee; }
  .product-table__item:last-child {
    margin-bottom: 15px; }

.headv1__topmenu--ul1 {
  width: 120px !important; }

.headv1__topmenu--div1 {
  float: left;
  /* border: solid 2px red; */
  margin-top: 26px; }

.headv1__div1-item {
  font-size: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  color: #C92838;
  transition-duration: 0.5s; }
  .headv1__div1-item:hover {
    background-color: transparent;
    color: #fff; }

.headv1__topmenu--div2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px; }
  .headv1__topmenu--div2 a {
    text-decoration: none; }

.headv1__topmenu--div2-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }

.headv1__div2-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: #fff;
  transition-duration: 0.5s; }
  .headv1__div2-icon:hover {
    background-color: #fff;
    color: #C92838; }

.headv1--large-icons {
  padding-left: 10px; }

.pushy-right ul:first-child {
  margin-top: 30px; }

.side-angle-right {
  margin-right: 10px; }

.menu-section h5 {
  padding-top: 30px;
  padding-left: 25px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 10px; }

.menu-section a {
  padding: 5px 30px !important; }

.pushy {
  font-family: "Quicksand", sans-serif;
  font-size: 1.6rem;
  position: fixed;
  width: 300px;
  height: 100%;
  top: 0;
  z-index: 9999;
  background: #C92838;
  overflow: auto;
  visibility: hidden;
  -webkit-overflow-scrolling: touch;
  /* enables momentum scrolling in iOS overflow elements */ }

.pushy a {
  display: block;
  color: #fff;
  padding: 10px 30px;
  text-decoration: none;
  outline: 0; }

.pushy a:hover {
  color: #fff;
  background-color: #E1495E; }

.pushy-menu--dark > a {
  background-color: #c02636; }

.pushy.pushy-left {
  left: 0; }

.pushy.pushy-right {
  right: 0; }

/* Menu Movement */
.pushy-left {
  transform: translate3d(-300px, 0, 0); }

.pushy-open-left #container,
.pushy-open-left .push {
  transform: translate3d(300px, 0, 0); }

.pushy-right {
  transform: translate3d(300px, 0, 0); }

.pushy-open-right #container,
.pushy-open-right .push {
  transform: translate3d(-300px, 0, 0); }

.pushy-open-left .pushy,
.pushy-open-right .pushy {
  transform: translate3d(0, 0, 0); }

/* Menu Transitions */
#container,
.pushy,
.push {
  transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99); }

/* Site Overlay */
.site-overlay {
  display: none; }

.pushy-open-left .site-overlay,
.pushy-open-right .site-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fade 500ms; }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Submenu Appearance */
.pushy-submenu {
  /* Submenu Icon */ }

.pushy-submenu ul {
  padding-left: 15px;
  transition: max-height 0.2s ease-in-out; }

.pushy-submenu ul .pushy-link {
  transition: opacity 0.2s ease-in-out; }

.pushy-submenu > a {
  position: relative; }

.pushy-submenu > a::after {
  content: '\f105';
  font-family: 'FontAwesome';
  display: block;
  width: 8px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  transition: transform 0.2s; }

/* Submenu Movement */
.pushy-submenu-closed ul {
  max-height: 0;
  overflow: hidden; }

.pushy-submenu-closed .pushy-link {
  opacity: 0; }

.pushy-submenu-open {
  /* Submenu Icon */ }

.pushy-submenu-open ul {
  max-height: 1000px; }

.pushy-submenu-open .pushy-link {
  opacity: 1; }

.pushy-submenu-open a::after {
  transform: translateY(-50%) rotate(90deg); }

.no-csstransforms3d .pushy-submenu-closed ul {
  max-height: none;
  display: none; }

/*========== BANNERS ==========*/
.main-slider {
  position: relative; }
  .main-slider .slick-dots {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%; }
  .main-slider .slick-dots li.slick-active button::before {
    background-color: white; }
  .main-slider .slick-dots li button::before {
    background-color: rgba(255, 255, 255, 0.3); }
  .main-slider .slick-prev, .main-slider .slick-next {
    height: 50px; }
  .main-slider .slick-prev {
    left: 30px; }
  .main-slider .slick-next {
    right: 30px; }
  .main-slider .slick-prev:before, .main-slider .slick-next:before {
    font-size: 50px;
    color: #fff; }

.banner {
  height: 520px;
  background-image: url("/images/banner.jpg");
  background-size: cover;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative; }

.banner__content {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 5;
  width: 100%; }
  .banner__content h3 {
    color: #fff;
    font-size: 3rem;
    font-weight: 400; }
  .banner__content p {
    color: #fff;
    margin: 0 0 20px;
    font-size: 1.4rem;
    font-weight: 300; }

.banner__btn {
  margin-top: 30px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 2.4rem;
  color: #fff;
  transition-duration: 0.2s;
  border: solid 1px #EEEEEE;
  border-radius: 6px;
  padding: 6px 24px 6px 24px; }
  .banner__btn:hover {
    color: #E1495E;
    border-color: #E1495E; }

/*========== FEATURED ITEMS ==========*/
.ctn--feat {
  background-color: #C92838;
  background-image: url(/img/grad.png);
  background-repeat: repeat; }
  .ctn--feat .fl__item {
    margin: 0px;
    padding: 0px; }

.fi__row .fl__item {
  -ms-flex-align: end;
  align-items: flex-end;
  margin: 0px;
  padding: 0px; }

.fi .fi__content {
  padding: 15px 15px 45px;
  width: 100%;
  position: relative; }
  .fi .fi__content a:hover {
    text-decoration: none; }
  .fi .fi__content .btn {
    position: absolute;
    bottom: 8px;
    right: 8px; }

.fi .fi__heading {
  font-size: 1.6rem;
  margin-bottom: 10px; }

.fi .fi__excerpt {
  margin: 0;
  color: #fff;
  font-size: 1.3rem; }

.fi__heading-hardcoded span {
  color: #fff;
  display: block; }

.featured__button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }

.ctn--feat .fi__heading {
  color: #fff !important; }

.ctn--feat2 .fi__content {
  display: -ms-flexbox;
  display: flex;
  flexwrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center; }

.ctn--feat2 .fi__heading {
  color: #C92838;
  font-size: 2rem !important;
  padding: 15px 0px;
  text-align: center; }

.ctn--feat2 .fi__text {
  text-align: center; }

/*========== FOOTER ==========*/
footer {
  position: relative;
  z-index: 1; }

.footer__main-container {
  padding-top: 0px !important;
  padding-bottom: 0 !important; }

.footer__second-container {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.footer__upper-container {
  background-color: #E1495E;
  padding-bottom: 15px; }

.footer__content-container {
  padding-top: 20px; }

/* .footer__collumns {
  border: solid 2px red;p

  display: block; 
  }*/
.footer__contact {
  font-family: "Raleway", sans-serif;
  font-size: 3rem;
  text-align: left;
  margin-top: 30px; }

.footer__social {
  font-family: "Raleway", sans-serif;
  font-size: 3rem;
  margin-top: 30px; }

.footer__contact--address {
  margin-top: 30px; }
  .footer__contact--address span {
    display: block; }
    .footer__contact--address span:nth-child(1):before {
      font-family: FontAwesome;
      content: "\f041";
      padding-right: 15px; }
    .footer__contact--address span:nth-child(2):before {
      font-family: FontAwesome;
      content: "\f095";
      padding-right: 12px; }
    .footer__contact--address span:nth-child(3):before {
      font-family: FontAwesome;
      content: "\f1ac";
      padding-right: 10px; }
    .footer__contact--address span:nth-child(4):before {
      font-family: FontAwesome;
      content: "\f003";
      padding-right: 10px; }

.footer__contact--phone {
  text-align: left;
  width: 50%;
  font-weight: 400;
  font-size: 1.2rem; }

.footer__contact--phone span {
  display: block; }

.footer__contact--copyright {
  font-family: "Open Sans", sans-serif;
  margin-top: 30px;
  /* text-align: left; */
  width: 100%;
  font-weight: 400;
  font-size: 1.2rem; }

.footer__contact--copyright div:nth-child(1) {
  border-left: 0px; }

/* .footer__concept-icon {
  margin-bottom: 20px;  
}  */
.footer__social-icons {
  padding-top: 20px;
  clear: both;
  /* float:left; */ }

.footer__social-icons-icons {
  /* float:left; */
  text-align: center;
  clear: both;
  display: inline;
  border-spacing: 10px 10px;
  border-collapse: separate;
  font-size: 20px; }

.footer__social-link {
  width: 36px;
  height: 36px;
  margin: 0 0px;
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
  color: #E1495E !important; }

.footer__social-icons-bg {
  width: 50px;
  height: 50px;
  color: #C92838;
  background-color: #fff;
  border-radius: 50%;
  display: inline; }

.verticalline {
  border-left: solid 1px #fff;
  display: inline;
  padding-left: 4px;
  padding-right: 4px; }

.footer__tweets {
  text-align: left;
  margin-top: 30px; }

.footer__tweets--feed {
  font-family: "Open Sans", sans-serif;
  margin-top: 30px;
  text-align: left;
  width: 100%;
  font-weight: 400;
  font-size: 1.2rem; }

.footer__tweets--feed span {
  display: block; }

.footer__logo {
  text-align: center; }
  .footer__logo img {
    margin-top: 60px; }

.footer-top {
  background-color: #C92838;
  text-align: left;
  color: #fff; }
  .footer-top a {
    color: #fff; }

.footer-top__logo {
  width: 100px;
  margin: 0 auto 20px; }

.footer-top__detail {
  font-size: 1.8rem;
  font-weight: 800;
  display: block; }
  .footer-top__detail + .footer-top__detail {
    margin: 20px 0; }

.footer-top__detail--email a {
  padding: 10px;
  border: 3px solid #fff;
  color: #fff;
  display: inline-block;
  text-decoration: none !important; }

.footer-top__detail--copy {
  margin-top: 15px;
  font-size: 1.3rem; }

@font-face {
  font-family: 'iCandy-icons';
  src: url("/fonts/iCandy-icons.eot?lkx0x");
  src: url("/fonts/iCandy-icons.eot?lkx0x#iefix") format("embedded-opentype"), url("/fonts/iCandy-icons.ttf?lkx0x") format("truetype"), url("/fonts/iCandy-icons.woff?lkx0x") format("woff"), url("/fonts/iCandy-icons.svg?lkx0x#iCandy-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

i.icd {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iCandy-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icd-icandy-icon:before {
  content: "\e901"; }

.icd-syrup-icon:before {
  content: "\e900"; }

.ftr__signature {
  text-align: center;
  background-color: #c02636;
  color: #fff;
  padding: 10px 0;
  font-size: 1.3rem; }
  .ftr__signature a {
    color: #fff; }
  .ftr__signature i.icd {
    font-size: 2.2rem;
    margin-right: 5px;
    vertical-align: -4px; }

/*========== HOME ==========*/
.download-container {
  background-color: #E1495E; }

.download-body {
  padding-top: 40px;
  padding-bottom: 40px;
  display: -ms-flexbox;
  display: flex;
  flexwrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column; }
  .download-body h3 {
    color: #fff;
    padding: 20px;
    font-weight: 300;
    margin: 0; }
  .download-body__picture {
    background-color: #fff;
    border-radius: 50%;
    padding: 10px; }
  .download-body__button {
    font-family: "Quicksand", sans-serif;
    border: solid 1px #fff;
    border-radius: 8px;
    color: #fff;
    transition-duration: 0.5s;
    min-width: 180px; }
    .download-body__button:after {
      font-family: FontAwesome;
      content: '\f1c1'; }
    .download-body__button:hover {
      color: #E1495E;
      border-color: #E1495E;
      background-color: #fff; }

.pink-boxes {
  padding-bottom: 30px;
  color: #fff;
  text-align: center; }

.pink-boxes__item {
  display: -ms-flexbox;
  display: flex;
  background-color: #E1495E;
  border-radius: 5px;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 20px 30px 20px;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px; }
  .pink-boxes__item__btn {
    margin-top: 30px;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 2rem;
    color: #fff;
    transition-duration: 0.5s;
    border: solid 1px #EEEEEE;
    border-radius: 6px;
    padding: 6px 24px 6px 24px; }
    .pink-boxes__item__btn:hover {
      color: #E1495E;
      border-color: #E1495E;
      background-color: #fff; }
  .pink-boxes__item__img {
    background-color: #fff;
    border: solid 8px #fff;
    border-radius: 50%; }
  .pink-boxes__item__title {
    padding-top: 20px; }
    .pink-boxes__item__title h3 {
      color: #fff; }
  .pink-boxes__item__link {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    border-style: solid;
    border: solid 2px rgba(255, 255, 255, 0.2);
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    text-align: left; }
    .pink-boxes__item__link a {
      text-decoration: none;
      color: #fff; }
    .pink-boxes__item__link:last-child {
      border: solid 2px rgba(255, 255, 255, 0.2);
      border-left: 0px;
      border-right: 0px; }
    .pink-boxes__item__link__container span:nth-child(2) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      width: 25px;
      height: 25px;
      float: right;
      color: #000;
      background-color: #fff;
      border-radius: 50%;
      border: solid 2px #E1495E;
      transition-duration: 0.5s; }
      .pink-boxes__item__link__container span:nth-child(2):hover {
        color: #fff;
        border-color: #fff;
        background-color: #E1495E; }

.angle-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 25px;
  height: 25px;
  float: right;
  color: #E1495E;
  background-color: #fff;
  border-radius: 50%;
  border: 0;
  transition-duration: 0.2s;
  padding: 0; }
  .angle-button:hover {
    border: solid 1px #fff;
    color: #fff;
    background-color: transparent; }

.angle-button2 {
  margin-top: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px;
  float: right;
  color: #fff;
  background-color: #C92838;
  border-radius: 50%;
  border: 0;
  transition-duration: 0.2s;
  padding: 0; }
  .angle-button2:hover {
    border: solid 1px #C92838;
    color: #C92838;
    background-color: transparent; }

.angle-button-cta {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-left: 15px;
  color: #C92838;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #C92838;
  transition-duration: 0.2s;
  padding: 0; }
  .angle-button-cta i {
    margin-top: 5px; }
  .angle-button-cta:hover {
    color: #fff;
    background-color: #C92838;
    border-color: #fff; }

/*========== CATEGORY ==========*/
.fi--news .fi__img {
  margin-bottom: 15px; }

.category-main {
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  padding: 15px 0px;
  width: 100%; }
  .category-main .fi__content h5 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600;
    color: #C92838;
    margin-bottom: 3px; }
  .category-main .fi__content p {
    color: #000; }

.category__btn {
  border: 1px solid #C92838;
  color: #C92838;
  background-color: transparent;
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
  font-size: 2rem;
  margin-bottom: 5px;
  padding: 5px 20px 5px 20px;
  transition-duration: 0.2s; }
  .category__btn + .category__btn {
    margin-left: 5px; }
  .category__btn:hover {
    background-color: #C92838;
    color: #fff;
    border-color: #fff; }

.container--category {
  display: -ms-flexbox;
  display: flex;
  flexwrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column; }
  .container--category h3 {
    color: #C92838; }

.category__right {
  padding-left: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-pack: end;
  justify-content: flex-end; }
  .category__right .job__ref div {
    font-weight: 700;
    color: #C92838;
    display: block; }
    .category__right .job__ref div span {
      color: #000;
      font-weight: 300; }
  .category__right .job__apply-button {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px; }

.category__tags {
  padding-top: 40px;
  display: inline; }
  .category__tags a {
    text-decoration: none; }
    .category__tags a:hover {
      color: #C92838;
      text-decoration: underline; }
      .category__tags a:hover h5 {
        color: #C92838; }
  .category__tags h5 {
    padding: 10px 40px;
    display: inline; }

/*========== ARTICLE ==========*/
.job__details {
  padding: 15px;
  background-color: #EEEEEE;
  margin: 0 0 15px;
  border-radius: 10px; }
  .job__details .job__details-row {
    display: block;
    margin: 0 0 5px; }
    .job__details .job__details-row strong {
      color: #C92838; }

.article-sidebar {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #EEEEEE;
  border-radius: 10px;
  padding: 15px 30px; }
  .article-sidebar h3 {
    color: #C92838; }
  .article-sidebar h4 {
    color: #C92838; }
  .article-sidebar p {
    color: #000; }
  .article-sidebar__inner {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    flexwrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .article-sidebar__inner:last-child .article-sidebar__inner-item {
      border-bottom: none; }
    .article-sidebar__inner-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: start;
      justify-content: flex-start;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      padding: 10px 0; }

.article__left-side h1 {
  color: #C92838;
  font-size: 3rem; }

.container--article h1 {
  color: #C92838; }

.related__title {
  margin: 10px 0 0;
  width: 100%; }

.relateditem__title {
  margin: 10px 0;
  font-size: 1.8rem;
  width: 100%; }
  .relateditem__title a {
    color: #C92838; }

.apply-btn {
  width: 100%; }

/*========== SLICES ==========*/
.slice {
  padding: 30px 0;
  position: relative; }
  .slice .slice__btn {
    border: 1px solid #000;
    color: #000;
    background-color: transparent;
    margin-top: 10px;
    border-radius: 5px;
    font-family: "Raleway", sans-serif;
    font-size: 2rem;
    padding: 5px 40px 5px 40px; }
  .slice__bgimg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.3;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
  .slice.slice--hasbg {
    background-color: #fff;
    color: #fff; }
    .slice.slice--hasbg .slice__heading {
      color: #fff; }
    .slice.slice--hasbg a.slice__btn:hover {
      background-color: #9e202c; }
    .slice.slice--hasbg.slice--fullwidth .slice__content--text {
      width: 100%;
      text-align: center; }
  .slice .slice__content--text + .slice__content--img, .slice .slice__content--img + .slice__content--text {
    margin-top: 20px; }

.slice.slice--white {
  color: #000;
  background-color: #fff; }
  .slice.slice--white .slice__heading {
    color: #C92838; }
  .slice.slice--white a.slice__btn {
    color: #C92838;
    border: 1px solid #C92838; }

.slice.slice--red {
  color: #fff;
  background-color: #C92838; }
  .slice.slice--red .slice__heading {
    color: #fff; }
  .slice.slice--red a.slice__btn {
    color: #fff;
    border: 1px solid #fff; }

.slice.slice--pink {
  color: #fff;
  background-color: #E1495E; }
  .slice.slice--pink .slice__heading {
    color: #fff; }
  .slice.slice--pink a.slice__btn {
    color: #fff;
    border: 1px solid #fff; }

.slice.slice--orange {
  color: #fff;
  background-color: #EF7E00; }
  .slice.slice--orange .slice__heading {
    color: #fff; }
  .slice.slice--orange a.slice__btn {
    color: #fff;
    border: 1px solid #fff; }

.slice.slice--grey {
  color: #000;
  background-color: #EEEEEE; }
  .slice.slice--grey .slice__heading {
    color: #C92838; }
  .slice.slice--grey a.slice__btn {
    color: #C92838;
    border: 1px solid #C92838; }

.slice--fullwidth .container {
  padding-top: 45px;
  padding-bottom: 45px; }

.slice--background {
  background-color: #eee; }

.slice__content--text + .slice__content--img, .slice__content--img + .slice__content--text {
  margin-top: 20px; }

.arrow-down {
  /* background-color:red; */
  width: 0;
  height: 0;
  transform: rotate(150deg);
  border-left: 0px solid transparent;
  border-right: 90px solid transparent;
  border-top: 30px solid #fff;
  position: absolute;
  bottom: 40px;
  left: 100px;
  /* z-index: 100; */ }

.slick-next.slick-arrow, .slick-prev.slick-arrow {
  display: none !important; }

.slice--testimonial {
  background-color: #C92838; }
  .slice--testimonial .container {
    padding-top: 60px;
    padding-bottom: 60px; }
  .slice--testimonial .slick-dots li button::before {
    border: 1px solid #C92838;
    background-color: #C92838;
    height: 10px;
    width: 10px; }
  .slice--testimonial .slick-dots li.slick-active button::before {
    background-color: #fff; }
  .slice--testimonial .slick-dots li.slick-active button::before {
    background-color: #c92838; }
  .slice--testimonial .slick-dots li button::before {
    background-color: rgba(201, 40, 56, 0); }
  .slice--testimonial .slick-dots li {
    margin: 1px !important; }

.testimonial-container-in {
  background-color: #fff;
  max-width: 750px;
  margin: 0 auto;
  padding: 30px;
  position: relative;
  text-align: center;
  overflow: hidden;
  border-radius: 5px; }
  .testimonial-container-in .slick-list {
    border-radius: 5px; }

.testimonial-slider {
  position: relative; }

.testimonial {
  position: relative;
  height: 160px;
  display: table !important;
  width: 100%; }
  .testimonial .testimonial-in {
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 20px; }
  .testimonial .quote {
    display: block;
    margin: 0 auto;
    max-width: 600px;
    margin-top: 15px; }
  .testimonial .name {
    color: #C92838;
    display: block;
    font-size: 1.8rem; }

.slice__gallery ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  .slice__gallery ul .slice__gallery-img {
    margin-bottom: 16px; }

/*========== CTAS ==========*/
.container-cta {
  display: -ms-flexbox;
  display: flex;
  flexwrap: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center; }

.cta {
  background-color: #EF7E00;
  color: #fff;
  padding: 10px 0;
  text-align: center; }
  .cta a {
    color: #fff;
    font-family: "Raleway", sans-serif; }
  .cta--images {
    text-align: center; }
    .cta--images .cta-logo-img {
      display: inline-block;
      max-width: 150px;
      padding: 10px; }
  .cta.cta--slider .credit-banner {
    padding: 0 45px; }
  .cta.cta--slider .credit {
    padding: 15px; }
  .cta.cta--slider .slick-next::before, .cta.cta--slider .slick-prev::before {
    color: #fff; }

.cta.cta--white {
  color: #000;
  background-color: #fff; }
  .cta.cta--white a.cta__link {
    color: #000; }

.cta.cta--red {
  color: #fff;
  background-color: #C92838; }
  .cta.cta--red a.cta__link {
    color: #fff; }

.cta.cta--pink {
  color: #fff;
  background-color: #E1495E; }
  .cta.cta--pink a.cta__link {
    color: #fff; }

.cta.cta--orange {
  color: #fff;
  background-color: #EF7E00; }
  .cta.cta--orange a.cta__link {
    color: #fff; }

.cta.cta--grey {
  color: #000;
  background-color: #EEEEEE; }
  .cta.cta--grey a.cta__link {
    color: #000; }

/*========== COOKIE NOTICE ==========*/
#cookie-notice-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #C92838;
  color: #fff;
  padding: 10px 0;
  font-size: 12px;
  z-index: 5; }
  #cookie-notice-container p {
    margin-bottom: 0; }
  #cookie-notice-container a {
    color: #fff;
    text-decoration: underline; }

#cookie-notice-container
#cookie-notice-container
#cookie-notice-container .btn {
  display: block !important;
  margin-top: 5px;
  width: 100%;
  border-radius: 0;
  background-color: #ea2b30; }

#cookie-notice-container .btn:hover {
  color: #fff; }

/*========== OUTDATED BROWSER NOTICE ==========*/
#browser-notice-container {
  width: 100%;
  background-color: #175caa;
  padding: 10px 0;
  z-index: 9;
  color: #fff; }

#browser-notice-container p {
  width: 990px;
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
  color: #fff; }

#browser-notice-container a {
  color: #fff;
  text-decoration: underline; }

#browser-notice-container button {
  margin: 0 10px;
  color: #fff; }

#browser-notice-container i {
  margin-right: 5px; }

/*========= PRODUCT =========*/
.page-container {
  margin: 30px 0; }

.prod-title {
  margin-top: 0;
  margin-bottom: 15px; }

.prod-code {
  margin-top: 0;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 15px; }

.prod-rating {
  margin-bottom: 10px;
  color: #e7711b; }

.prod-price {
  font-size: 30px;
  margin-bottom: 15px; }

.color-details {
  margin-bottom: 15px; }

.swatches {
  margin: 0.5em 0;
  padding-left: 0; }

.swatches li {
  border: 2px solid #fff;
  display: inline-block;
  list-style: outside none none;
  margin: 2px 1px;
  vertical-align: top; }

.swatches .selected, .swatches li:hover {
  border: 2px solid #b2b2b2; }

.swatches a {
  background-position: center center;
  background-repeat: no-repeat;
  border: 2px solid #fff;
  box-shadow: 1px 1px 2px #c5c5c5;
  display: block;
  height: 33px;
  text-align: center;
  transition: all 0.25s ease 0s;
  width: 33px; }

.swatches a img {
  height: 29px;
  width: 29px; }

.prod-options {
  margin-bottom: 15px; }

.prod-btns {
  margin-bottom: 15px; }

.btn-buy {
  background-color: #4ec67f;
  font-size: 22px;
  color: #fff;
  font-weight: 300; }

.prod-stock {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 15px; }

.prod-stock i.green {
  color: #4ec67f; }

/*========== PAGE - CART ==========*/
.cart-table th, .cart-table td {
  text-align: center;
  vertical-align: middle !important;
  padding: 15px !important;
  border-color: #eee !important; }

.cart-table th {
  border-bottom: 1px solid #eee !important; }

.cart-table .cart-description {
  text-align: left;
  position: relative; }

.cart-table .cart-link {
  font-size: 11px;
  text-transform: uppercase;
  color: #bbb;
  transition-duration: 200ms;
  cursor: pointer; }

.cart-table .cart-link:hover {
  text-decoration: none;
  color: #888; }

.cart-table .cart-link:last-child {
  margin-left: 10px; }

.cart-table .prod-title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500; }

.cart-table .prod-code {
  font-weight: 300;
  margin-bottom: 10px; }

.cart-table .prod-options {
  font-weight: 300; }

.cart-table th {
  display: none; }

.cart-table td {
  display: block; }

.cart-table tr {
  margin-bottom: 15px;
  float: left;
  border: 1px solid #eee;
  border-top: 0; }

.cart-table td.cart-table-quantity, .cart-table td.cart-table-price {
  display: table-cell;
  width: 50%; }

.cart-table td.cart-table-products .img-responsive {
  max-width: 70%; }

.btn-quant {
  color: #106489;
  background-color: #fff; }

.btn-quant.plus {
  margin-left: 0 !important; }

.cart-checkout-box {
  border: 1px solid #eee;
  padding: 15px;
  margin-bottom: 15px; }

.cart-checkout-box h5 {
  font-weight: 300; }

#cart-summary {
  width: 100%;
  margin-bottom: 10px; }

#cart-summary tr {
  border-bottom: 1px solid #eee; }

#cart-summary tr:last-child {
  border-bottom: 0; }

#cart-summary tr td {
  padding: 8px; }

#cart-summary tr td:nth-child(2) {
  text-align: right; }

#cart-summary .total-price {
  font-size: 24px;
  font-weight: 500; }

/*========== PAGE - LOGIN ==========*/
.login-title {
  border-bottom: 1px solid #eee;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 15px;
  padding: 0 0 10px; }

#login-guest-checkout {
  margin-top: 60px; }

/*========== PAGE - CHECKOUT ==========*/
#same-address {
  background-color: #eee;
  padding: 5px 10px; }

.cart-checkout-box h3 {
  margin: 10px 0 20px;
  font-size: 22px;
  font-weight: 400; }

.checkout-flow {
  display: inline-block;
  width: 100%;
  text-align: center; }

.checkout-flow ul {
  position: relative;
  display: inline-block;
  padding-top: 30px; }

.checkout-flow ul::before {
  background-color: #eee;
  content: "";
  display: block;
  height: 1px;
  left: 43px;
  position: absolute;
  right: 30px;
  top: 20px; }

.checkout-flow li {
  display: inline-block;
  padding: 10px;
  position: relative;
  width: 60px;
  text-align: center; }

.checkout-flow li::before {
  background-color: #fff;
  border: 1px solid;
  border-radius: 20px;
  display: block;
  font-family: "FontAwesome";
  height: 30px;
  left: 50%;
  margin-left: -1em;
  padding: 4px 0;
  position: absolute;
  text-align: center;
  top: -25px;
  width: 30px; }

.checkout-flow li.cart::before {
  content: "\f07a"; }

.checkout-flow li.checkout::before {
  content: "\f0c9"; }

.checkout-flow li.payment::before {
  content: "\f154"; }

.checkout-flow li.confirmation::before {
  content: "\f00c"; }

.checkout-flow li.active {
  color: #e7711b; }

.checkout-flow li.complete {
  color: #4ec67f; }

.flow-label {
  display: none; }

/*========== PAGE - CHECKOUT CONFIRM ==========*/
.confirm-title {
  margin-top: 30px; }

.confirm-social {
  margin: 30px 0; }

.confirm-social i {
  font-size: 35px;
  color: #ccc; }

/*========== PLUGIN - TOUCHSPIN ==========*/
.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell; }

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 8px 10px;
  margin-left: -1px;
  position: relative; }

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  border-top-right-radius: 4px; }

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
  border-radius: 0;
  border-bottom-right-radius: 4px; }

.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 9px;
  font-weight: normal; }

/*========== CONTACT PAGE ==========*/
#map-canvas {
  width: 100%;
  height: 350px; }

.contact__box {
  background-color: #E1495E;
  border-bottom: 8px solid #C92838;
  padding: 15px;
  color: #fff; }
  .contact__box a {
    color: #fff; }

#contact-form button {
  background-color: #E1495E;
  border-color: #E1495E; }

/*========== ACCOUNTS PAGES ==========*/
.account-box {
  border: 1px solid #eee;
  padding: 15px;
  margin-bottom: 15px; }

.account-box h3 {
  font-size: 22px;
  font-weight: 400;
  margin: 10px 0 20px; }

nav.account-menu {
  border: 1px solid #eee;
  border-radius: 10px; }

.account-menu li {
  border-bottom: 1px solid #eee; }

.account-menu li a {
  display: block;
  padding: 14px;
  transition-duration: 200ms; }

.account-menu li a:hover {
  background-color: #eee; }

.account-menu li.active a {
  background-color: #eee; }

.account-menu li:last-child {
  border-bottom: 0; }

.order-table th, .order-table td {
  vertical-align: middle !important;
  padding: 15px !important;
  border-bottom: 1px solid #eee !important; }

/*========== TYPOGRAPHY ==========*/
.search--item {
  margin: 0 0 30px; }
  .search--item .search--title {
    margin: 0 0 10px; }
    .search--item .search--title a {
      color: #C92838; }

/* Small Devices, Tablets IPAD*/
@media only screen and (min-width: 768px) {
  /*========== TYPOGRAPHY ==========*/
  /*========== BTNS ==========*/
  /*========== FORMS ==========*/
  /*========== HELPERS ==========*/
  /*========== GLOBAL CONTAINERS ==========*/
  .ctn {
    padding: 75px 0; }
  /*========== HEADER ==========*/
  .headv1__topmenu {
    height: 80px; }
    .headv1__topmenu ul {
      width: 160px; }
      .headv1__topmenu ul li > a {
        font-size: 2.4rem; }
  .headv1__logo {
    width: 180px; }
  .headv1__logo-small {
    width: 120px; }
  .headv1__logo-small2 {
    width: 120px;
    left: 80px; }
  .headv1__bottom {
    display: table;
    width: 100%;
    background-color: #337ab7;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .headv1__bottommenu {
    margin: 0 auto;
    max-width: 1200px; }
    .headv1__bottommenu .navbar-nav {
      margin: 0;
      display: table;
      width: 100%;
      table-layout: fixed;
      position: relative; }
      .headv1__bottommenu .navbar-nav > li {
        float: none;
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: top;
        position: static; }
        .headv1__bottommenu .navbar-nav > li > a {
          padding: 14px;
          height: 100%;
          display: table;
          width: 100%;
          text-align: center;
          color: #fff;
          font-size: 1.6rem; }
          .headv1__bottommenu .navbar-nav > li > a::after {
            content: "";
            position: absolute;
            top: 10%;
            right: 0;
            height: 80%;
            width: 2px;
            background-color: rgba(255, 255, 255, 0.1); }
          .headv1__bottommenu .navbar-nav > li > a:focus, .headv1__bottommenu .navbar-nav > li > a:hover, .headv1__bottommenu .navbar-nav > li > a:active {
            background-color: #337ab7; }
          .headv1__bottommenu .navbar-nav > li > a span {
            display: table-cell;
            height: 100%;
            vertical-align: middle; }
        .headv1__bottommenu .navbar-nav > li:last-child a::after {
          display: none; }
        .headv1__bottommenu .navbar-nav > li.open > a {
          background-color: #337ab7 !important; }
        .headv1__bottommenu .navbar-nav > li.open .dropdown-menu {
          width: 100%;
          border: none;
          background-color: #337ab7;
          padding: 20px 30px;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          box-shadow: none;
          color: #fff; }
          .headv1__bottommenu .navbar-nav > li.open .dropdown-menu a {
            color: #fff; }
          .headv1__bottommenu .navbar-nav > li.open .dropdown-menu .dropdown-menu__submenu {
            padding: 0;
            margin: 0;
            list-style: none; }
            .headv1__bottommenu .navbar-nav > li.open .dropdown-menu .dropdown-menu__submenu li {
              margin: 0 0 5px; }
              .headv1__bottommenu .navbar-nav > li.open .dropdown-menu .dropdown-menu__submenu li a {
                display: block;
                text-decoration: none !important; }
                .headv1__bottommenu .navbar-nav > li.open .dropdown-menu .dropdown-menu__submenu li a::before {
                  font-family: 'FontAwesome';
                  content: "\f105";
                  color: #fff;
                  margin-right: 10px; }
              .headv1__bottommenu .navbar-nav > li.open .dropdown-menu .dropdown-menu__submenu li:last-child {
                margin: 0; }
  .dropdown-menu__header {
    margin: 0 0 20px;
    font-size: 2.6rem; }
  .dropdown-menu__title {
    table-layout: auto;
    padding: 0 0 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0 0 20px; }
    .dropdown-menu__title--icon {
      width: 50px; }
    .dropdown-menu__title--arrow {
      text-align: right; }
  /*========== BANNERS ==========*/
  .banner {
    height: 520px; }
  .banner__content h3 {
    font-size: 4rem; }
  .banner__content p {
    font-size: 1.6rem; }
  .banner__content .container {
    width: 500px; }
  /*========== FEATURED ITEMS ==========*/
  .ctn--feat .fl__row:last-child {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2); }
  .ctn--feat .fi .fi__content {
    padding: 15px 15px 30px;
    width: 100%; }
  .ctn--feat .fi:nth-child(3n+1) {
    border-right: 2px solid rgba(255, 255, 255, 0.2); }
  .ctn--feat .fi:nth-child(3n+2) {
    border-right: 2px solid rgba(255, 255, 255, 0.2); }
  .fi .fi__heading {
    font-size: 1.8rem; }
  /*========== FOOTER ==========*/
  .footer-top__logo {
    width: 150px; }
  /*========== HOME ==========*/
  /*========== CATEGORY ==========*/
  .category-main {
    padding: 45px 0; }
  .category__right .job__apply-button {
    margin-top: 30px; }
  /*========== ARTICLE ==========*/
  .job__details {
    font-size: 1.8rem;
    margin-bottom: 30px; }
  .article-sidebar {
    margin-top: 0; }
  .apply-btn {
    width: auto; }
  .container--article {
    padding-top: 70px; }
  /*========== SLICES ==========*/
  .slice {
    padding: 0; }
    .slice__bgimg {
      display: block; }
    .slice.slice--hasbg {
      background-color: #C92838;
      color: #fff; }
      .slice.slice--hasbg .slice__container.slice--hasimg {
        position: relative;
        z-index: 9; }
        .slice.slice--hasbg .slice__container.slice--hasimg .slice__content--text {
          background-color: rgba(201, 40, 56, 0.75);
          color: #fff;
          padding: 30px; }
      .slice.slice--hasbg .slice__heading {
        color: #fff; }
  .slice__container {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-height: 350px; }
    .slice__container.slice--imgright .slice__content--text {
      -ms-flex-order: 1;
      order: 1; }
    .slice__container.slice--imgright .slice__content--img {
      -ms-flex-order: 2;
      order: 2;
      padding: 60px 0px 60px 60px; }
    .slice__container .slice__content {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center; }
    .slice__container .slice__content--text {
      padding: 60px 0 60px 0;
      width: 65%; }
    .slice__container .slice__content--img {
      padding: 60px 60px 60px 0;
      width: 35%; }
  .slice__content--text + .slice__content--img, .slice__content--img + .slice__content--text {
    margin-top: 0; }
  .testimonial-slider {
    position: relative;
    padding: 0 30px; }
  .testimonial .name {
    font-size: 3rem; }
  /*========== CTAS ==========*/
  .cta {
    font-size: 2.6rem;
    padding: 10px 0; }
    .cta a {
      color: #fff;
      text-decoration: none; }
  #cookie-notice-container p {
    font-size: 14px;
    text-align: center; }
  /*========== PRODUCT =========*/
  /*========== PAGE - CART ==========*/
  .cart-table th {
    display: table-cell; }
  .cart-table td {
    display: table-cell; }
  .cart-table-products {
    max-width: 300px;
    display: table-cell; }
  .confirm-table-products {
    max-width: 100px;
    display: table-cell; }
  .cart-table tr {
    margin-bottom: 0;
    float: none;
    border: none;
    border-top: auto; }
  .cart-table td.cart-table-quantity, .cart-table td.cart-table-price {
    display: table-cell;
    width: auto; }
  .cart-table .cart-description {
    min-width: 300px; }
  .cart-table td.cart-table-products .img-responsive {
    max-width: 100%; }
  /*========== PAGE - LOGIN ==========*/
  /*========== PAGE - CHECKOUT ==========*/
  .checkout-flow {
    margin-bottom: 30px; }
  .checkout-flow li {
    width: 100px; }
  .checkout-flow ul::before {
    right: 50px; }
  .flow-label {
    display: block; }
  /*========== PAGE - CHECKOUT CONFIRM ==========*/
  /*========== PLUGIN - TOUCHSPIN ==========*/
  /*========== CONTACT PAGE ==========*/
  #map-canvas {
    width: 100%;
    height: 400px; }
  .contact__box {
    padding: 30px;
    font-size: 1.8rem; }
  /* Medium Devices, Desktops */ }

@media only screen and (min-width: 992px) {
  /*========== BTNS ==========*/
  /*========== FORMS ==========*/
  /*========== HELPERS ==========*/
  .arrow-down {
    /* background-color:red; */
    left: 160px;
    /* z-index: 100; */ }
  /*========== GLOBAL TEXT STYLES ==========*/
  /*========== GLOBAL CONTAINERS ==========*/
  /*========== SYRUP LOGIN ==========*/
  /*========== SYRUP ADMIN BAR ==========*/
  /*========== COOKIE NOTICE ==========*/
  #cookie-notice-container .btn {
    display: inline-block !important;
    width: auto;
    float: right;
    margin-top: 0;
    font-size: 16px;
    background-color: #E1495E;
    border-radius: 40px; }
  /*========== OUTDATED BROWSER NOTICE ==========*/
  /*========== HEADER ==========*/
  /*========== FOOTER ==========*/
  /*========== HOME ==========*/
  .pink-boxes__item__link__container span {
    font-size: 1.4rem; }
  /*========== CATEGORY ==========*/
  /*========== EVENT ==========*/
  /*========== ARTICLE ==========*/
  /*========== SLICES ==========*/
  /*========== CTAS ==========*/
  /*========== PRODUCT =========*/
  /*========== PAGE - CART ==========*/
  /*========== PAGE - LOGIN ==========*/
  /*========== PAGE - CHECKOUT ==========*/
  /*========== PAGE - CHECKOUT CONFIRM ==========*/
  /*========== PLUGIN - TOUCHSPIN ==========*/
  /* Large Devices, Wide Screens */ }

@media only screen and (min-width: 1200px) {
  /*========== BTNS ==========*/
  /*========== FORMS ==========*/
  /*========== HELPERS ==========*/
  /*========== GLOBAL TEXT STYLES ==========*/
  /*========== GLOBAL CONTAINERS ==========*/
  header .container {
    width: 1170px; }
  footer .container {
    width: 1170px; }
  .container {
    width: 970px; }
  .container--large {
    width: 1170px; }
  .container--category {
    padding-top: 70px; }
  .category-get-job-container {
    width: 1170px; }
  /*========== SYRUP LOGIN ==========*/
  /*========== SYRUP ADMIN BAR ==========*/
  /*========== COOKIE NOTICE ==========*/
  /*========== OUTDATED BROWSER NOTICE ==========*/
  /*========== HEADER ==========*/
  .headv1__bottommenu .navbar-nav > li {
    position: relative; }
    .headv1__bottommenu .navbar-nav > li.right .dropdown-menu {
      left: auto;
      right: 0; }
    .headv1__bottommenu .navbar-nav > li.open .dropdown-menu {
      width: 500px; }
  /*========== FOOTER ==========*/
  /*========== HOME ==========*/
  /*========== CATEGORY ==========*/
  /*========== EVENT ==========*/
  /*========== ARTICLE ==========*/
  /*========== SLICES ==========*/
  /*========== CTAS ==========*/
  /*========== PRODUCT =========*/
  /*========== PAGE - CART ==========*/
  /*========== PAGE - LOGIN ==========*/
  /*========== PAGE - CHECKOUT ==========*/
  /*========== PAGE - CHECKOUT CONFIRM ==========*/
  /*========== PLUGIN - TOUCHSPIN ==========*/
  /*mobile only*/ }

@media only screen and (max-width: 477px) {
  .ctn--feat .fl__item {
    width: 100%; } }

@media only screen and (max-width: 550px) {
  .category__tags {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center; }
    .category__tags h5 {
      padding: 10px 10px;
      display: inline;
      font-size: 1.4rem; } }

@media only screen and (max-width: 707px) {
  .fl__row {
    -ms-flex-direction: column;
    flex-direction: column; } }

@media only screen and (max-width: 992px) {
  .article__right-side {
    padding-top: 50px; } }

@media only screen and (max-width: 768px) {
  .fi__content {
    border: 2px solid rgba(255, 255, 255, 0.2);
    padding: 15px;
    width: 100%;
    border-left: 0;
    border-right: 0;
    border-top: 0; }
  /*anti sticky js for mobile*/
  #checkoutBox {
    position: relative !important; }
  .pink-boxes__item {
    margin-left: 20px;
    margin-right: 20px; }
  .pink-boxes__item__link__container span {
    font-size: 1.2rem; } }

/*========== ANIMATIONS ==========*/
.flash {
  -webkit-animation-name: flash-animation;
  -webkit-animation-duration: 0.5s;
  animation-name: flash-animation;
  animation-duration: 0.5s; }

@keyframes flash-animation {
  from {
    background: #106489; }
  to {
    background: default; } }

/*# sourceMappingURL=base.css.map */
